import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import Error from '../../../../resources/images/failure-reservation.svg';
import Success from '../../../../resources/images/success-checkmark.svg';
import UserFriends from '../../../../resources/images/user-friends.svg';
import Warning from '../../../../resources/images/warning-reservation.svg';
import config from '../../../App/config';
import { convertPhoneNumberAsPerSbmChannel } from '../../utils/commonUtils';
import { checkoutDatalocator } from '../../../checkoutv2/checkoutAndOrderSummary/dataLocators';
import { SUNBELT_HEADER_LOGO_CTA_ID } from '../../../checkoutv2/constants';
import Button from '../../atoms/button/button';
import HelplineNumber from '../../atoms/helplineNumber/HelplineNumber';
import { ERROR, SUCCESS, WARNING } from '../../constants';
import './TransferOrderConfirmationScreen.scss';

const TransferOrderConfirmationScreen = props => {
    const {
        renderDetail,
        confirmationType,
        handlePrimaryButton,
        handleSecondaryButton,
        primaryButtonLabel,
        secondaryButtonLabel,
        primaryButtonDataTestId,
        secondaryButtonDataTestId,
        confirmationTitle
    } = props;
    const intl = useIntl();
    const phoneNumber = convertPhoneNumberAsPerSbmChannel(ENV_CONFIG.PHONE_NUMBER);

    const renderConfirmationStatus = confirmationType => {
        let iconComponent = null;

        switch (confirmationType) {
            case SUCCESS:
                iconComponent = (
                    <Success
                        data-testid={checkoutDatalocator?.reservation_result_icon_testid}
                        tabIndex={'-1'}
                        aria-hidden={true}
                    />
                );
                break;
            case WARNING:
                iconComponent = (
                    <Warning
                        data-testid={checkoutDatalocator?.reservation_result_icon_testid}
                        tabIndex={'-1'}
                        aria-hidden={true}
                    />
                );
                break;
            case ERROR:
                iconComponent = (
                    <Error
                        data-testid={checkoutDatalocator?.reservation_result_icon_testid}
                        tabIndex={'-1'}
                        aria-hidden={true}
                    />
                );
                break;
            default:
                iconComponent = null;
        }

        return (
            <>
                {iconComponent}
                <h4
                    data-testid={checkoutDatalocator?.reservation_resultLabel_testid}
                    id={checkoutDatalocator?.reservation_resultLabel_testid}
                    tabIndex={0}>
                    {confirmationTitle}
                </h4>
            </>
        );
    };
    useEffect(() => {
        if (document.getElementById(SUNBELT_HEADER_LOGO_CTA_ID)) {
            const headerLogoLink = document.querySelector(`#${SUNBELT_HEADER_LOGO_CTA_ID} a`);
            if (headerLogoLink) {
                headerLogoLink?.removeAttribute('href');
                headerLogoLink.title = 'Sunbelt Rentals Header Logo';
                headerLogoLink.tabIndex = 0;
            }
            document
                .getElementById(SUNBELT_HEADER_LOGO_CTA_ID)
                .setAttribute('data-testid', checkoutDatalocator.guest_sbrlogo_testid);
            document.getElementById(SUNBELT_HEADER_LOGO_CTA_ID).onclick = () => {
                window.location.href = window.location.origin + config.pagePaths.homePage;
            };
        }
    }, []);

    useEffect(() => {
        const body = document.querySelector('body');
        const header = document.getElementsByClassName('header__checkout')[0];
        header.classList.add('fixedHeader');
        header.classList.add('fixedHeader__transferorder');
        const backToCartHeaderBtn = document.querySelector('#back-to-cart-header-btn');
        backToCartHeaderBtn.style.display = 'none';
        const checkout = document.querySelector('.checkout');
        checkout.classList.add('confirmation_screen_content');
    }, []);

    return (
        <>
            <div className="confirmation_screen_container">
                <div className="confirmation_wrapper">
                    <div className="status_message_container">{renderConfirmationStatus(confirmationType)}</div>
                    {renderDetail()}
                    <div className="question_container">
                        <span>
                            <UserFriends />
                        </span>
                        <span>
                            <HelplineNumber
                                message={intl.formatMessage({ id: 'reservation:helpine-support' })}
                                textDataLocator={checkoutDatalocator.reservation_question_call}
                                phonenumberDataLocator={checkoutDatalocator.reservation_phonenumber}
                                phoneNumber={phoneNumber}
                            />
                        </span>
                    </div>
                    <div className="button_container">
                        <div className="reservation-button-container">
                            <Button
                                className={`button button-primary button-block`}
                                type="button"
                                buttonAriaLabel={primaryButtonLabel}
                                onClick={handlePrimaryButton}
                                data-testid={primaryButtonDataTestId}>
                                {primaryButtonLabel}
                            </Button>
                            {secondaryButtonLabel && (
                                <Button
                                    className={'button button-outline-primary'}
                                    type="button"
                                    buttonAriaLabel={secondaryButtonLabel}
                                    onClick={handleSecondaryButton}
                                    data-testid={secondaryButtonDataTestId}>
                                    {secondaryButtonLabel}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransferOrderConfirmationScreen;
