import React from 'react';
import { bool } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../contexts/cart';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { useFilterState } from '../../cap';
import HelplineNumber from '../../global/atoms/helplineNumber/HelplineNumber';
import ReservationContinueButtons from './ReservationContinueButtons';
import Alert from '../../global/atoms/alert/alert';
import { convertPhoneNumberAsPerSbmChannel } from '../../global/utils/commonUtils';
import Success from '../../../resources/images/success-checkmark.svg';
import Warning from '../../../resources/images/warning-reservation.svg';
import Error from '../../../resources/images/failure-reservation.svg';
import UserFriends from '../../../resources/images/user-friends.svg';
import './confirmation.scss';
import { ENV_CONFIG } from '../../../constants/envConfig';
export default function ReservationConfirmationStatus({ isGuestUser }) {
    const intl = useIntl();
    const [{ submitReservation }] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const { confirmationType } = submitReservation;
    //to check the confirmationType and render the header
    //icon and message accordingly
    const renderReservationStatus = () => {
        switch (confirmationType) {
            case 1:
                return (
                    <>
                        <Success
                            data-testid={checkoutDatalocator?.reservation_result_icon_testid}
                            tabIndex={'-1'}
                            aria-hidden={true}
                        />
                        <h4
                            data-testid={checkoutDatalocator?.reservation_resultLabel_testid}
                            id={checkoutDatalocator?.reservation_resultLabel_testid}
                            tabIndex={0}>
                            {intl.formatMessage({ id: 'reservation:success' })}
                        </h4>
                    </>
                );
            case 2:
                return (
                    <>
                        <Warning
                            data-testid={checkoutDatalocator?.reservation_result_icon_testid}
                            tabIndex={'-1'}
                            aria-hidden={true}
                        />
                        <h4
                            data-testid={checkoutDatalocator?.reservation_resultLabel_testid}
                            id={checkoutDatalocator?.reservation_resultLabel_testid}
                            tabIndex={0}>
                            {intl.formatMessage({ id: 'reservation:request-received' })}
                        </h4>
                    </>
                );
            case 3:
                return (
                    <>
                        <Error
                            data-testid={checkoutDatalocator?.reservation_result_icon_testid}
                            tabIndex={'-1'}
                            aria-hidden={true}
                        />
                        <h4
                            data-testid={checkoutDatalocator?.reservation_resultLabel_testid}
                            id={checkoutDatalocator?.reservation_resultLabel_testid}
                            tabIndex={0}>
                            {intl.formatMessage({ id: 'reservation:connect-message' })}
                        </h4>
                    </>
                );
            default:
                return null;
        }
    };
    //to check the confirmation type and render the
    //body content accordingly
    const renderReservationDetail = () => {
        switch (confirmationType) {
            case 1:
                return (
                    <div className="reservation-details-container" tabIndex={0}>
                        <div className="reservation-number-container">
                            <span>{intl.formatMessage({ id: 'reservation:reservation-number-message' })}</span>
                            <h5 data-testid={checkoutDatalocator?.reservation_Number_testid}>
                                {submitReservation?.refNumber}
                            </h5>
                        </div>
                        <span
                            className="success-delivery-message"
                            data-testid={checkoutDatalocator?.reservation_successSummary_msg_testid}>
                            {!viewCart?.isInStorePickup
                                ? intl.formatMessage({ id: 'reservation:delivery-message' })
                                : intl.formatMessage({ id: 'reservation:delivery-message-ispu' })}
                        </span>
                    </div>
                );
            case 2:
                    return (
                        <div className="reservation-details-container" tabIndex={0}>
                            <div className="reservation-number-container">
                                <span>{intl.formatMessage({ id: 'reservation:reservation-number-message' })}</span>
                                <h5 data-testid={checkoutDatalocator?.reservation_Number_testid}>
                                    {submitReservation?.refNumber}
                                </h5>
                            </div>
                            <span data-testid={checkoutDatalocator?.reservation_warningSummary_msg_testid} tabIndex={0}>
                                {intl.formatMessage({ id: 'reservation:gathering-equipment-message' })}
                            </span>
                        </div>
                    );
            case 3:
                return (
                    <div className="reservation-details-container" tabIndex={0}>
                        <div className="reservation-number-container">
                            <span data-testid={checkoutDatalocator?.reservation_failureSummary_msg_testid}>
                                {intl.formatMessage({ id: 'reservation:inventory-unavailabily-message' })}
                            </span>
                        </div>
                        <span className="reservation-reference-number">
                            {intl.formatMessage({
                                id: 'reservation:reference-number-message'
                            })}{' '}
                            <span data-testid={checkoutDatalocator?.reservation_Number_testid}>
                                {submitReservation?.refNumber}
                            </span>
                        </span>
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <div className="reservation-confirmation-container">
            <div className="reservation-confirmation-wrapper">
                <div className="reservation-message-container">{renderReservationStatus()}</div>
                {renderReservationDetail()}
                <div className="question-container">
                    <span>
                        <UserFriends />
                    </span>
                    <span>
                        <HelplineNumber
                            message={intl.formatMessage({ id: 'reservation:helpine-support' })}
                            textDataLocator={checkoutDatalocator.reservation_question_call}
                            phonenumberDataLocator={checkoutDatalocator.reservation_phonenumber}
                            phoneNumber={convertPhoneNumberAsPerSbmChannel(ENV_CONFIG.PHONE_NUMBER)}
                        />
                    </span>
                </div>
            </div>
            {!isGuestUser && <ReservationContinueButtons />}
        </div>
    );
}
ReservationConfirmationStatus.propsType = {
    isGuestUser: bool
};
ReservationConfirmationStatus.defaultProps = {
    isGuestUser: true
};
