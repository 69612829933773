import React from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../contexts/cart';
import Alert from '../../global/atoms/alert/alert';
import HelplineNumber from '../../global/atoms/helplineNumber/HelplineNumber';
import CreateAccount from './CreateAccount';
import { convertPhoneNumberAsPerSbmChannel } from '../../global/utils/commonUtils';
import MobileSuccess from '../../../resources/images/mobile-success-checkmark.svg';
import MobileWarning from '../../../resources/images/mobile-warning-reservation.svg';
import MobileError from '../../../resources/images/mobile-failure-reservation.svg';
import UserFriends from '../../../resources/images/user-friends.svg';
import { ENV_CONFIG } from '../../../constants/envConfig';
export default function MobileGuestReservationConfirmation() {
    const intl = useIntl();
    const [{ submitReservation }] = useCartState();
    const { confirmationType } = submitReservation;
    const onCreateAccount = () => {};
    //to check the confirmationType and render the header
    //icon and message accordingly
    const renderReservationStatus = () => {
        switch (confirmationType) {
            case 1:
                return (
                    <>
                        <MobileSuccess tabIndex={-1} ariaHidden={true} />
                        <h5 tabIndex={0}>{intl.formatMessage({ id: 'reservation:success' })}</h5>
                    </>
                );
            case 2:
                return (
                    <>
                        <MobileWarning tabIndex={-1} ariaHidden={true} />
                        <h5 tabIndex={0}>{intl.formatMessage({ id: 'reservation:request-received' })}</h5>
                    </>
                );
            case 3:
                return (
                    <>
                        <MobileError tabIndex={-1} ariaHidden={true} />
                        <h5 tabIndex={0}>{intl.formatMessage({ id: 'reservation:connect-message' })}</h5>
                    </>
                );
            default:
                return null;
        }
    };
    //to check the confirmation type and render the
    //body content accordingly
    const renderMobileGuestReservationDetail = () => {
        switch (confirmationType) {
            case 1:
                return (
                    <div className="mobile-reservation-details-container" tabIndex={0}>
                        <div className="reservation-number-container">
                            <small>{intl.formatMessage({ id: 'reservation:reservation-number-message' })}</small>
                            <h6>{submitReservation?.refNumber}</h6>
                        </div>
                        <small>{intl.formatMessage({ id: 'reservation:delivery-message' })}</small>
                    </div>
                );
            case 2:
                    return (
                        <div className="mobile-reservation-details-container" tabIndex={0}>
                            <div className="reservation-number-container">
                                <small>{intl.formatMessage({ id: 'reservation:reservation-number-message' })}</small>
                                <h6>{submitReservation?.refNumber}</h6>
                            </div>
                            <small>{intl.formatMessage({ id: 'reservation:gathering-equipment-message' })}</small>
                        </div>
                    );
            case 3:
                return (
                    <div className="mobile-reservation-details-container">
                        <div className="reservation-number-container" tabIndex={0}>
                            <small>{intl.formatMessage({ id: 'reservation:inventory-unavailabily-message' })}</small>
                        </div>
                        <small className="reservation-reference-number">{`${intl.formatMessage({
                            id: 'reservation:reference-number-message'
                        })} ${submitReservation?.refNumber}`}</small>
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <div className="mobile-guest-reservation-status-container">
            <div className="reservation-confirmation-wrapper">
                <div className="reservation-message-container">{renderReservationStatus()}</div>
                {renderMobileGuestReservationDetail()}
                <div className="question-container">
                    <span>
                        <UserFriends tabIndex={'-1'} aria-hidden={true} />
                    </span>
                    <span tabIndex={0}>
                        <HelplineNumber
                            message={intl.formatMessage({ id: 'reservation:helpine-support' })}
                            phoneNumber={convertPhoneNumberAsPerSbmChannel(ENV_CONFIG.PHONE_NUMBER)}
                        />
                    </span>
                </div>
            </div>
            <CreateAccount onCreateAccount={onCreateAccount} createAccountCTADataLocator={'create-account-testid'} />
        </div>
    );
}
